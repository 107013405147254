<template>

  <b-container fluid id="app" class="body-content cover-full"> 
    
      <!-- NAVBAR-->
      <b-row align-v="center">
        <b-col sm="12">
          <b-navbar  toggleable="md"  sticky fixed="top" style="background-color: rgba(255, 255, 255, 0.7); " >
            <b-navbar-brand to="" class="mx-3">
              <img src="/files/imagenes/logo.jpg" style="height: 35px;" class="d-inline-block align-top" >
            </b-navbar-brand>  
          
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav  class="mx-auto">
                <b-nav-item @click="$router.push('/inicio')" class="mx-4 " ><b-icon icon="house-fill" font-scale=".9" variant="danger" /><b class="items-nav titles"> Inicio</b></b-nav-item >
                <b-nav-item @click="$router.push('/municipio')" class="mx-4"><b-icon icon="bell-fill" font-scale=".9" variant="danger" /><b class="items-nav titles"> Turismo </b></b-nav-item>
                <b-nav-item @click="$router.push('/gobierno')" class="mx-4"><b-icon icon="diagram3-fill" font-scale=".9" variant="danger" /><b class="items-nav titles"> Gobierno</b></b-nav-item>
                <b-nav-item @click="$router.push('/transparencia')" class="mx-4"><b-icon icon="search" font-scale=".9" variant="danger" /><b class="items-nav titles"> Transparencia</b></b-nav-item>
                <b-nav-item @click="$router.push('/mejoraregulatoria')" class="mx-4"><b-icon icon="list-check" font-scale=".9" variant="danger" /><b class="items-nav titles"> Mejora Regulatoria</b></b-nav-item>
              </b-navbar-nav>               
            </b-collapse>
              
          </b-navbar>
        </b-col>
      </b-row>

    <b-row align="center" align-h="center" style="min-height:780px;">
      <b-col cols="12" >
        <router-view/>
      </b-col>
    </b-row>

<div class="elfsight-app-0ce028bd-ed57-4250-b80d-735338530019"></div>

    <!-- FOOTER -->
    <b-container fluid id="footer" >
      <br>
      <b-row align-v="center">
        <b-col cols="12" md="12" lg="3" >
          <!--
            <img src="@/assets/logo teo largo.png" style="height: 35px;" class="d-inline-block align-top mb-3" >
            -->
          <b-card-text class="mt-3">
            <b-icon icon="geo-alt-fill"/><b> {{resources.domicilio}}</b>
          </b-card-text>
        </b-col>
        <b-col cols="12" md="12" lg="3" >
          <b-card-text class="mt-3">
            <b-icon icon="telephone-fill"/><b> {{resources.telefono_principal}}</b>
          </b-card-text>
        </b-col>
<!--
        Plaza Principal S/N Col. Centro 56070 Tepetlaoxtoc Centro, State of Mexico, Mexico


        <b-col sm="12" md="6" lg="3" class="mt-3">
          <b-card-text>
            <b>Sitios De Interes</b>
          </b-card-text>
          <b-card-text>
            <a href="https://www.facebook.com/" class="social-links">
              <b-icon icon="link" class="social-icons"></b-icon> Sitio 1
            </a>
          </b-card-text>
          <b-card-text>
            <a href="https://www.facebook.com/" class="social-links">
              <b-icon icon="link" class="social-icons"></b-icon> Sitio 2
            </a>
          </b-card-text>
        </b-col>
        -->

        <b-col cols="12" md="6" lg="3">
          <b-card-text class="mt-3 pointer" @click="showAvisoDePrivacidad=true">
            <b-icon icon="file-earmark-text-fill"/><b> Aviso De Privacidad</b>
          </b-card-text>
        </b-col>
        <b-col cols="12" md="6" lg="3" >
          <b-card-text class="mt-3 pointer" @click="showTerminosYCondiciones=true">
            <b-icon icon="file-earmark-text-fill"/><b> Terminos y Condiciones </b>
          </b-card-text>
        </b-col>
<!--
        <b-col sm="12" md="6" lg="3" class="mt-3">
          <b-card-text>
            <b>Redes Sociales</b>
          </b-card-text>
          <b-card-text>
            <a href="https://www.facebook.com/" class="social-links"><b-icon icon="facebook" class="social-icons"></b-icon></a>
          </b-card-text>
          <b-card-text>
            <a href="https://twitter.com/?lang=es" class="social-links"><b-icon icon="twitter" class="social-icons"></b-icon></a>
          </b-card-text>
          <b-card-text>
            <a href="https://www.youtube.com/" class="social-links"><b-icon icon="youtube" class="social-icons"></b-icon></a>
          </b-card-text>
        </b-col>
-->
      </b-row>
      <br>
      
    </b-container>

     <b-modal v-model="showAvisoDePrivacidad" size="lg" title="Avisos de Privacidad" header-bg-variant="dark" header-text-variant="light" hide-footer>
      <b-row>
        <b-col cols="12">
         <b-img fluid src="https://teotihuacan.gob.mx/files/imagenes/avisos-head.jpg"></b-img>
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <b-col class="mt-3" cols="12" v-for="aviso in avisos" :key="aviso.pdf"> 
           <a target="blank_" :href="'https://teotihuacan.gob.mx/files/avisosprivacidad/'+aviso.pdf.replace(' ','%20')"><h6><b-icon icon="file-earmark-lock2"/> {{aviso.pdf.substring(21, aviso.pdf.length).replace('_',' ').replace('.pdf','')}}</h6></a>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal v-model="showTerminosYCondiciones" size="lg" title="Terminos Y Condiciones" hide-footer>
      <p>{{resources.terminos_condiciones}}</p>
    </b-modal>

    <!-- <b-modal header-bg-variant="dark"  body-bg-variant="dark" v-model="showModalPrincipal" size="lg" hide-header hide-footer>
      <b-row no-gutters>
        <b-col style="border:solid;">
          <div style="border:solid;">
            <b-embed        
              style="width:100%; height:600px;"
              type="iframe"
              :src="resources.contenido_modal.url"
            ></b-embed>
          </div>
        </b-col>
      </b-row>
      <b-row align-h="center" align="center">
        <b-col>
          <b-button pill size="sm" @click="showModalPrincipal=false">
            Cerrar
          </b-button>
        </b-col>
      </b-row>
    </b-modal> -->
  </b-container>
</template>

<script src="https://apps.elfsight.com/p/platform.js" defer></script>

<script>
  import axios from "axios";
  var TwitterWidgetsLoader = require("twitter-widgets");

  export default {
    mounted() {
/*
      let headIntegra2 = document.createElement('script')
      headIntegra2.setAttribute('src', 'https://code.jquery.com/jquery-3.4.1.min.js')
      document.head.appendChild(headIntegra2)

      let head2Integra2 = document.createElement('script')
      head2Integra2.setAttribute('src', 'https://www.infomexsinaloa.org/accesibilidadweb/js_api.aspx?api_key=3A0C44CF-6473-4BD5-8226-CF57BA1DB7BF&tipo=header&aut=teotihuacan.gob.mx')
      document.head.appendChild(head2Integra2)

      let bodyIntegra2 = document.createElement('script')
      bodyIntegra2.setAttribute('src', 'https://www.infomexsinaloa.org/accesibilidadweb/js_api.aspx?api_key=3A0C44CF-6473-4BD5-8226-CF57BA1DB7BF&tipo=body&aut=teotihuacan.gob.mx')
      document.body.appendChild(bodyIntegra2)

      let body2Integra2 = document.createElement('script')
      body2Integra2.setAttribute('src', 'https://www.infomexsinaloa.org/accesibilidadweb/js_api.aspx?api_key=3A0C44CF-6473-4BD5-8226-CF57BA1DB7BF&tipo=footer&aut=teotihuacan.gob.mx')
      document.body.appendChild(body2Integra2)
      
      */
      TwitterWidgetsLoader.load();


      var host = location.protocol + '//' + location.host;
      axios({
          method: 'get',
          url: host+'/files/inicio.json',
      })
      .then(res => {
          this.resources = res.data;
      }).catch(err => {
        alert(err);  
      });

      

    },
    data() {
      return {
        showAvisoDePrivacidad:false,
        showTerminosYCondiciones:false,
        resources:{
          domicilio:"",
        },
        showModalPrincipal:true,
        avisos:[
       
        {pdf:'APC CBDP18318AECU00X SIMPLIFICADO GENERAL TEOTIHUACAN.pdf', area:' '},
        {pdf:'APC CBDP18318AECU001 Talleres_Cultura y Museo.pdf', area:' '},
        {pdf:'APC CBDP18318AECU002 Puestas a Disposición_Seguridad Ciudadana.pdf', area:' '},
        {pdf:'APC CBDP18318AECU003 Procedimientos Administrativos_Contraloría Mpal.pdf', area:' '},
        {pdf:'APC CBDP18318AECU004 COCICOVO_Contraloría Mpal.pdf', area:' '},
        {pdf:'APC CBDP18318AECU005 Licencias de funcionamiento y permisos_Regulación Comercial.pdf', area:' '},
        {pdf:'APC CBDP18318AECU006 Quejas y Denuncias_Derechos Humanos.pdf', area:' '},
        {pdf:'APC CBDP18318AECU007 NNA Difusores_DIF.pdf', area:' '},
        {pdf:'APC CBDP18318AECU008 Terapia Física_DIF.pdf', area:' '},
        {pdf:'APC CBDP18318AECU009 paquetes productivos_DIF.pdf', area:' '},
        {pdf:'APC CBDP18318AECU010 Adultos Mayores_DIF.pdf', area:' '},
        {pdf:'APC CBDP18318AECU011 Familias fuertes_DIF.pdf', area:' '},
        {pdf:'APC CBDP18318AECU012 INAPAM_DIF.pdf', area:' '},
        {pdf:'APC CBDP18318AECU013 Convenios_DIF.pdf', area:' '},
        {pdf:'APC CBDP18318AECU014 Centro de Prevención_DIF.pdf', area:' '},
        {pdf:'APC CBDP18318AECU015 Terapia de Lenguaje_DIF.pdf', area:' '},
        {pdf:'APC CBDP18318AECU016 Odontología_DIF.pdf', area:' '},
        {pdf:'APC CBDP18318AECU017 Psicología_DIF.pdf', area:' '},
        {pdf:'APC CBDP18318AECU018 Trabajo Social_DIF.pdf', area:' '},
        {pdf:'APC CBDP18318AECU019 Recursos Humanos_Administración.pdf', area:' '},
        {pdf:'APC CBDP18318AECU020 Certificación de Clave y Valor Catastral_Catastro.pdf', area:' '},
        {pdf:'APC CBDP18318AECU021 Red de Apoyo al Emprendedor_Desarrollo Económico.pdf', area:' '},
        {pdf:'APC CBDP18318AECU022 Bolsa de Trabajo_Desarrollo Económico.pdf', area:' '},
        {pdf:'APC CBDP18318AECU023 Trámites_Desarrollo Urbano.pdf', area:' '},
        {pdf:'APC CBDP18318AECU024 Productores_Fomento Agropecuario.pdf', area:' '},
        {pdf:'APC CBDP18318AECU025 Padron Artesanos_Fomento Artesanal.pdf', area:' '},
        {pdf:'APC CBDP18318AECU026 Calidad Ambiental_Medio Ambiente.pdf', area:' '},
        {pdf:'APC CBDP18318AECU027 Asesoría Jurídica_Dirección de la Mujer.pdf', area:' '},
        {pdf:'APC CBDP18318AECU028 Padron agua potable_ODEAPAST.pdf', area:' '},
        {pdf:'APC CBDP18318AECU029 Procedimiento Arbitral_Of Calificadora 1t.pdf', area:' '},
        {pdf:'APC CBDP18318AECU030 Puestas a Dispisición_Of Calificadora 1t.pdf', area:' '},
        {pdf:'APC CBDP18318AECU031 Procedimiento Arbitral_Of Calificadora 2t.pdf', area:' '},
        {pdf:'APC CBDP18318AECU032 Puestas a Disposición_Of Calificadora 2t.pdf', area:' '},
        {pdf:'APC CBDP18318AECU033 Actos Estado Civil_Registro Civil.pdf', area:' '},
        {pdf:'APC CBDP18318AECU034 Constancias_OFMCC1.pdf', area:' '},
        {pdf:'APC CBDP18318AECU035 Actas_OFMCC1.pdf', area:' '},
        {pdf:'APC CBDP18318AECU036 Constancias_OFMCC2.pdf', area:' '},
        {pdf:'APC CBDP18318AECU037 Actas_OFMCC2.pdf', area:' '},
        {pdf:'APC CBDP18318AECU038 Expedientes del Servicio Militar_Secretaría del Aytto.pdf', area:' '},
        {pdf:'APC CBDP18318AECU039 Constancias de Domicilio_Secretaría del Aytto.pdf', area:' '},
        {pdf:'APC CBDP18318AECU040 Constancias de Residencia_Secretaría del Aytto.pdf', area:' '},
        {pdf:'APC CBDP18318AECU041 Constancias de Ingresos_Secretaría del Aytto.pdf', area:' '},
        {pdf:'APC CBDP18318AECU042 Constancias de Ientidad_Secretaría del Aytto.pdf', area:' '},
        {pdf:'APC CBDP18318AECU043 Constancias de No Afectación_Secretaría del Aytto.pdf', area:' '},
        {pdf:'APC CBDP18318AECU044 Autoridades Auxiliares_Secretaría del Aytto.pdf', area:' '},
        {pdf:'APC CBDP18318AECU045 Constancias de Depedencia Económica_Secretaría del Aytto.pdf', area:' '},
        {pdf:'APC CBDP18318AECU046 Constancias de Modo Honesto de Vivir_Secretaría del Aytto.pdf', area:' '},
        {pdf:'APC CBDP18318AECU047 Constancias de Buena Conducta_Secretaría del Aytto.pdf', area:' '},
        {pdf:'APC CBDP18318AECU048 Carta de Recomendación_Secretaría del Aytto.pdf', area:' '},
        {pdf:'APC CBDP18318AECU049 Constacias de Identidad_Secretaría del Aytto.pdf', area:' '},
        {pdf:'APC CBDP18318AECU050 Permisos_Secretaría del Aytto.pdf', area:' '},
        {pdf:'APC CBDP18318AECU051 Oficialía de Partes_Secretaría del Aytto.pdf', area:' '},
        {pdf:'APC CBDP18318AECU052 Padron de Contribuyentes_Tesorería Municipal.pdf', area:' '},
        {pdf:'APC CBDP18318AECU053 SAIMEX SARCOEM_Unidad de Transparencia.pdf', area:' '},
        {pdf:'APC CBDP18318AECU054 Asuntos Jurídicos_Unidda Jurídica y Consultiva.pdf', area:' '},
        {pdf:'APC CBDP18318AECU055 Reportes de Atención hopitalaria_Protección Civil.pdf', area:' '},
        {pdf:'APC CBDP18318AECU056 Libro de Registro_Derechos Humanos.pdf', area:' '},
        {pdf:'APC CBDP18318AECU057 Asesoría Psicológica_Dirección de la Mujer.pdf', area:' '},
        {pdf:'APC CBDP18318AECU058 Cursos_Desarrollo Social.pdf', area:' '},
        ]
      }
    }
  };
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#footer {
  background: linear-gradient(90deg, rgba(255,31,31,1) 0%, rgba(220,0,0,1) 100%);
  color: #fff;
}

.parallax {
  /* The image used */
  background-image: url("https://teotihuacan.gob.mx/files/imagenes/teotihuacanp.jpg");

  /* Set a specific height */
  min-height: 880px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}



.img {
  border-radius: 20px;
  height: 300px;
  width: 300px;
  box-shadow: 5px 5px 20px -2px #5f5f5f;
}

.img-carousel{
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.body-content {
  background-color: white;
}

.target-card {
  background-color: red;
  border-radius: 2em;
  border: 1px solid;
  padding: 10px;
  box-shadow: 5px 5px rgb(170, 163, 163);
  color: #fff;
}

.cover-full {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100% !important;
}

@font-face {
  font-family: 'Crossten Extrabold';
  src: url('assets/fonts/Horizon Type - Crossten Extrabold.otf') 

}

.titles {
  font-family: 'Crossten Extrabold';
}

.items-nav {
  color:rgb(229,0,0);
}

.social-links{ 
  color: white !important; 
  text-decoration: none;
}

.social-icons {
  width: 20px; 
  height: 20px;
}

.icons-cards {
  width: 120px; height: 120px;
}


/* styles flipbook*/


.action-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.action-bar .btn {
  font-size: 30px;
  color: black;
}
.action-bar .btn svg {
  bottom: 0;
}
.action-bar .btn:not(:first-child) {
  margin-left: 10px;
}
.has-mouse .action-bar .btn:hover {
  color: black;
  filter: drop-shadow(1px 1px 5px #000);
  cursor: pointer;
}
.action-bar .btn:active {
  filter: none !important;
}
.action-bar .btn.disabled {
  color: black;
  pointer-events: none;
}
.action-bar .page-num {
  font-size: 12px;
  margin-left: 10px;
}




@media (min-width: 871px)  {
  .offset-flipbook {
    margin-bottom:15px;
  }
}
@media (min-width: 781px) and (max-width: 870px) {
  .offset-flipbook {
    margin-bottom:10px;
  }
}
@media (min-width: 641px) and (max-width: 780px) {
  .offset-flipbook {
    margin-bottom:-20px;
  }
}
@media (min-width: 589px) and (max-width: 640px) {
  .offset-flipbook {
    margin-bottom:-75px;
  }
}
@media (min-width: 521px) and (max-width: 588px) {
  .offset-flipbook {
    margin-bottom:15px;
  }
}
@media (min-width: 451px) and (max-width: 520px) {
  .offset-flipbook {
    margin-bottom:30px;
  }
}
@media (max-width: 450px) {
  .offset-flipbook {
    margin-bottom: -10px;
  }
}



@media (min-width: 872px)  {
  .offset-button-flipbook {
    margin-top:75px;
  }
}
@media (min-width: 781px) and (max-width: 871px) {
  .offset-button-flipbook {
    margin-top:75px;
  }
}
@media (min-width: 641px) and (max-width: 780px) {
  .offset-button-flipbook {
     margin-top:-10px;
  }
}
@media (min-width: 589px) and (max-width: 640px) {
  .offset-button-flipbook {
    margin-top: -115px;
  }
}
@media (min-width: 521px) and (max-width: 588px) {
  .offset-button-flipbook {
    margin-top: 70px;
  }
}

@media (min-width: 451px) and (max-width: 520px) {
  .offset-button-flipbook {
    margin-top:75px;
  }
}

@media (max-width: 450px) {
  .offset-button-flipbook {
    margin-top: 10px;
  }
}




.action-bar {
  width: 100%;
  height: 30px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;

}
.action-bar .btn {
  font-size: 30px;
  color: black;
}
.action-bar .btn svg {
  bottom: 0;
}
.action-bar .btn:not(:first-child) {
  margin-left: 10px;
}
.has-mouse .action-bar .btn:hover {
  color: black;
  filter: drop-shadow(1px 1px 5px #000);
  cursor: pointer;
}
.action-bar .btn:active {
  filter: none !important;
}
.action-bar .btn.disabled {
  color: black;
  pointer-events: none;
}
.action-bar .page-num {
  font-size: 12px;
  margin-left: 10px;
}


.flipbook .viewport {
  width: 200vw;
  /*height: calc(100vh - 50px - 40px);*/
  height: 100vh;
}

.flipbook {
  height: 90vh;
}

.flipbook .bounding-box {
  box-shadow: 5px 5px 20px -2px #5f5f5f;
}


#flipbook-body {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  color: #ccc;
}

.c-shadow-sm{
  box-shadow: 5px 5px 20px -2px #5f5f5f;
  margin-bottom:10px;
  border-radius:25px !important;
}
.img-supposition-rounded{
  background: linear-gradient(90deg, rgba(28,81,141,1) 0%, rgba(0,57,120,1) 100%);
  box-shadow: 4px 4px 15px 1px #5f5f5f;
  border-radius: 50%;
  font-size:20px;
  padding:20px;
  width: 98px;
  height: 98px;
  position:absolute;
  top:-49px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}


.imagebackground1{
  border-radius: 20px;
  background-image: url("assets/plaza1.jpg");
}

.imagebackground2{
  border-radius: 20px;
  background-image: url("assets/plaza2.jpg");
}

.imagebackground3{
  border-radius: 20px;
  background-image: url("assets/plaza3.jpg");
}

.redtransparent{
  border-radius: 20px;
  background:rgba(255, 0, 0, 0.671); 
  padding:7%;
  }





</style>


