import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/index.html',
    redirect: '/inicio'
  },
  {
    path: '/',
    redirect: '/inicio'
  },
  {
    path: '/inicio',
    name: 'Inicio',
    component: () => import( '../views/Inicio.vue')
  },
  {
    path: '/municipio',
    name: 'Municipio',
    component: () => import( '../views/Municipio.vue')
  },
  {
    path: '/gobierno',
    name: 'Gobierno',
    component: () => import( '../views/Gobierno.vue')
  },
  {
    path: '/transparencia',
    name: 'Transparencia',
    component: () => import( '../views/Transparencia.vue')
  },
  {
    path: '/mejoraregulatoria',
    name: 'MejoraRegulatoria',
    component: () => import( '../views/MejoraRegulatoria2024.vue')
  },
  {
    path: '/mejoraregulatoria2023',
    name: 'MejoraRegulatoria2023',
    component: () => import( '../views/MejoraRegulatoria2023.vue')
  },
  {
    path: '/mejoraregulatoria2022',
    name: 'MejoraRegulatoria2022',
    component: () => import( '../views/MejoraRegulatoria2022.vue')
  },
  {
    path: '/whitepage',
    name: 'WhitePage',
    component: () => import( '../views/WhitePage.vue')
  },
  {
    path: '/dif',
    name: 'Dif',
    component: () => import( '../views/DifDirectorio.vue')
  },
  {
    path: '/primerinforme',
    name: 'PInforme',
    component: () => import( '../views/PrimerInforme.vue')
  },
  {
    path: '/imcufidet',
    name: 'Imcufidet',
    component: () => import( '../views/Imcufidet.vue')
  },
  {
    path: '/odeapast',
    name: 'Odeapast',
    component: () => import( '../views/Odeapast.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
